<template>
    <div class="row justify-content-center align-items-md-center">
      <div class="col-md-8">
        <div class="card full-height bg-ligth">
          <div class="card-header">
            <h3 class="mb-0">{{ lista_campos != '' ? 'Editar' : 'Crear' }} Diagnóstico</h3>
          </div>
          <div class="card-body">
            <form action="">
              <div class="form-group row">
                <div class="col-md-8">
                  <label class="col-md-12">{{$t('DIAG.NOME')}}</label>
                  <input
                      required
                      type="text"
                      class="form-control"
                      v-model="form.nome"
                      placeholder="Escriba el nombre..."
                  />
                </div>
              </div>
          
  
              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-md-12">{{$t('ESCRIBIR.NOME')}}*</label>
                  <input
                      required
                      type="text"
                      class="form-control"
                      v-model="form.descricao"
                      placeholder="Describa el Diagnóstico..."
                  />
                </div>
              </div>
          
  
              <div class="form-group row">
                <div class="col-md-12 text-center">
                  <button
                      @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
                      class="btn btn-primary"
                      :disabled="verif"
                  >
                  {{$t('SALVAR.NOME')}}
                    <b-spinner
                        v-show="verif"
                        small
                        variant="dark"
                        type="grow"
                        label="Spinning"
                    ></b-spinner>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import {fireAlert} from "@/core/mixins/alertMessageMixin";
  
  export default {
    mixins: [fireAlert],
    data() {
      return {
        form: {
          nome: "",
          descricao: "",
          status: 1,
        },
        verif: false,
      };
    },
    created() {
      this.listar_requerimentos();
      this.preenxerCampos();
    },
    computed: {
      lista_campos() {
        return this.$store.state.configEmpresa.lista_campos;
      },
      mensagem_alert() {
        return this.$store.state.configEmpresa.mensagem_alert;
      },
      lista_filials() {
        return this.$store.state.configEmpresa.lista_filials;
      },
    },
    methods: {
      async confirm(tipo) {
        await this.$confirm({
          title: "Estas seguro?",
          message: `Esta accion  ` + tipo + ` un diagnostico en el sistema.`,
          button: {
            no: "No",
            yes: "Si",
          },
          callback: (confirm) => {
            if (confirm) {
              if (tipo == "cria") this.create();
              if (tipo == "edita") this.update();
            }
          },
        });
      },
      async create() {
        this.verif = true;
        await this.$store.dispatch("configEmpresa/create_diagnostico", this.form);
        this.verif = false;
        this.fireAlert({
          ...this.mensagem_alert, routeName: 'listarDiagnostico'
        });
      },
      async update() {
        this.verif = true;
        await this.$store.dispatch("configEmpresa/update_diagnostico", this.form);
        this.verif = false;
        this.fireAlert({
          ...this.mensagem_alert, routeName: 'listarDiagnostico'
        });
      },
      listar_requerimentos() {
        this.$store.dispatch("configEmpresa/listar_filial");
      },
      preenxerCampos() {
        if (this.lista_campos != "")
          this.form = {
            id: this.lista_campos.id,
            nome: this.lista_campos.nome,
            descricao: this.lista_campos.descricao,
            status: this.lista_campos.status,
          };
      },
    },
  };
  </script>
  
  <style scoped>
  .lista:hover {
    background-color: rgba(167, 183, 255, 0.705);
  }
  </style>